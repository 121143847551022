import React from "react"
import { 
    MdPlace,
    MdPhone,
  } from 'react-icons/md';
  import logo from "../../content/assets/logo-black.svg"

const Mainmenu = props =>{ 
    const classes = "studio-main-menu" + (props.visible ? " studio-main-menu--fixed" : "");
return (
  <section className={classes}>
        <div className="studio-main-menu-in">
            <div className="studio-main-menu-in-container">
            <a href={"tel:"+props.settings.phone} className="studio-main-menu-phone">
                        <MdPhone className="studio-footer-icon"></MdPhone>
                        <span className="text">{props.settings.phone}</span>
                    </a>
                <div className="studio-main-menu-logo">
                    <img src={logo} alt=""/>
                </div>
                <a href={props.settings.googlemaplink} target="_blank" rel="noreferrer" className="studio-main-menu-address">
                        <MdPlace className="studio-footer-icon"></MdPlace>
                        <div className="studio-main-menu-address-text">
                            <div className="text">{props.settings.address}</div>
                            <div className="studio-main-menu-address-subtext">{props.settings.address2}</div>

                        </div>
                    </a>
            </div>
            <div className="studio-main-menu-in-container studio-main-menu-in-container--mobile">
                <div className="studio-main-menu-logo">
                    <img src={logo} alt=""/>
                </div>
                <div className="df-separator"></div>
                <div className="studio-main-menu-contacts">
                    <a href={"tel:"+props.settings.phone} className="studio-main-menu-phone">
                    <MdPhone className="studio-footer-icon"></MdPhone>
                      <span className="text">{props.settings.phone}</span>
                    </a>
                    <a href={props.settings.googlemaplink} target="_blank" rel="noreferrer" className="studio-main-menu-address">
                    <MdPlace className="studio-footer-icon"></MdPlace>
                    <div className="studio-main-menu-address-text">
                        <div className="text">{props.settings.address}</div>
                        <div className="studio-main-menu-address-subtext">{props.settings.address2}</div>
                    </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
)
}

export default Mainmenu
