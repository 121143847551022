import React from "react"
import Gallery from "../components/gallery"
import { useStaticQuery, graphql } from "gatsby"
import {
  FaInstagram,
}from 'react-icons/fa';


const Portfolio = props =>{ 
  // console.log(props);
  const data = useStaticQuery(graphql`
  {
    file(sourceInstanceName: {eq: "gallery"}, name: {eq: "gallery"}) {
      childMdx {
          frontmatter {
            columns
            pictures {
              description
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
    }
  }
`);
const gallery = data.file.childMdx.frontmatter;
const pictures = gallery.pictures;
const columnsNum = gallery.columns;

  return (    <section className="studio-portfolio mb100">
  <div className="studio-container-1200">
      <div className="studio-portfolio__in studio-in">
          <div className="studio-portfolio-maintitle mb60 tac">
              <h3 className="studio-portfolio-subtitle ffpd tac color-gold">Miksi tykkää minusta</h3>
              <h1 className="studio-title studio-portfolio-title ffpd tac fz60">KUVAGALLERIA</h1>
          </div>

          <Gallery pictures={pictures} columnsNum={columnsNum}/>

          <div className="studio-portfolio-btns tac">
          {
                    props.instagram && (
                    <a href={"https://www.instagram.com/"+props.instagram+"/"} target="_blank" rel="noreferrer" className="studio-btn-secondary ffpd">
                    <FaInstagram className="studio-footer-icon"></FaInstagram>
                        <span className="vam">Seuraa instagramia</span>
                    </a>
                    )
          }
          </div>
      </div>
  </div>
</section>
  
)}



export default Portfolio
