import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { 
  MdPlace,
  MdPhone,
} from 'react-icons/md';
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
}from 'react-icons/fa';
import designerLogo from "../assets/img/elena-logo-short.png"
import photographerLogo from "../assets/img/photographer-logo.png"


const Footer = (props) => {
const settings = props.settings;
const data = useStaticQuery(graphql`
{
  file(name: {eq: "hairdresser1"}) {
    name
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`)

const bgImage = data.file.childImageSharp;

// console.log(settings);
  
  return (
  <section className="studio-footer">
<BackgroundImage className="studio-container-1200 studio-footer-pic color-white studio-in" fluid={bgImage.fluid}>
    <div className="studio-overlay"></div>
    <div className="mb40">
        <div className="studio-footer-columns">
            <div className="studio-footer-column studio-footer-column-left">
                <h2 className="ffpd fz40 color-gold mb20">Yhteystiedot</h2>
                <div className="studio-footer-info-list mb20">
                    <div className="studio-footer-info">
                        <svg aria-hidden="true" focusable="false" className="studio-footer-icon" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z">
                            </path>
                        </svg>
                        {settings.hours}
                    </div>
                    <a href={"tel:"+settings.phone} className="studio-footer-info">
                        <MdPhone className="studio-footer-icon"></MdPhone>
                        <span className="text">{settings.phone}</span>
                    </a>
                    <a href={settings.googlemaplink} target="_blank" rel="noreferrer" className="studio-footer-info">
                        <MdPlace className="studio-footer-icon"></MdPlace>
                        <span className="text">{settings.address}, {settings.address2}</span>
                    </a>
                    {
                    settings.instagram && (<a href={"https://www.instagram.com/"+settings.instagram+"/"} target="_blank" rel="noreferrer" className="studio-footer-info">
                    <FaInstagram className="studio-footer-icon"></FaInstagram>
                        {settings.instagram}
                    </a>)
                    }
                    {
                    settings.facebook && (<a href={"https://www.facebook.com/"+settings.facebook} target="_blank" rel="noreferrer" className="studio-footer-info">
                    <FaFacebook className="studio-footer-icon"></FaFacebook>
                        {settings.facebook}
                    </a>)
                    }
                    {
                      settings.twitter && (<a href={"https://www.twitter.com/"+settings.twitter} target="_blank" rel="noreferrer" className="studio-footer-info">
                        <FaTwitter className="studio-footer-icon"></FaTwitter>
                              {settings.twitter}
                          </a>)
                    }
                </div>
            </div>
            <div className="studio-footer-column" dangerouslySetInnerHTML={{__html: settings.googlemaphtml}}>
              
            </div>
        </div>
    </div>
    <div className="studio-footer-logos">
      
    <div className="studio-footer-logo studio-designer-logo">
      <a href="https://www.linkedin.com/in/aseevaes/?locale=fi_FI" target="_blank" rel="noreferrer"><img src={designerLogo} alt="designer logo"></img></a>
    </div>
    <div className="studio-footer-logo studio-photographer-logo">
      <a href="https://www.instagram.com/elena.taranukhina/" target="_blank" rel="noreferrer"><img src={photographerLogo} alt="photographer logo"></img></a>
    </div>

    </div>
    <p className="studio-copyright tac ffpd color-gold ">{settings.sitename} © {new Date().getFullYear()} All right reserved.</p>
</BackgroundImage>
</section>
)
}


export default Footer
