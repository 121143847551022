import React, { useState, useEffect, createRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import servicesWm from "../../content/assets/services-wm.svg"
import { 
  MdPhone,
} from 'react-icons/md';
import {isNotInViewport} from "../utils/utils"



const Services = props => {
    const data = useStaticQuery(graphql`
    {
      file(sourceInstanceName: {eq: "gallery"}, name: {eq: "services"}) {
        childMdx {
          frontmatter {
            services {
              title
              text
              image {
                  id
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const services = data.file.childMdx.frontmatter.services;
  const servicesDiv = createRef();
  const [active, setActive] = useState(0);
  const[pause, setPause] = useState(false);
  useEffect(()=>{
      // console.log("services timer start");
      const timer = setInterval(scrollRightTimer, 4000);
      return ()=>{
        // console.log("services timer stop");
        clearInterval(timer)
      }
})
return (

  <section ref={servicesDiv} className="studio-services">
  <h1 className="studio-big-tittle">
    <img src={servicesWm} alt="palvelut"></img>
  </h1>
  <div className="studio-container-950">
      <div className="studio-services-maintitle mb60 tac">
          <h3 className="studio-services-subtitle ffpd tac color-gold">Suosittelemme</h3>
          <h1 className="studio-title studio-services-title ffpd tac fz60">PALVELUT</h1>
      </div>

      <div onMouseEnter={()=>{setPause(true)}} onMouseLeave={()=>{setPause(false)}} role="presentation" className="studio-services-slides">
          {
              services.map((item,i)=>{
                  // console.log(item, item.image);
                  let classes = "studio-services-slide anim-fadeIn"+(active === i ? " studio-services-slide--active": "");
                return (
                <div key={item.image.id} className= {classes}>
                    <div className="studio-services-content">
                        {/* <div className="studio-services-pic">
                            <Image fluid={item.image.childImageSharp.fluid} alt={item.title} className="studio-services-img"/>
                        </div> */}
                        <BackgroundImage fluid={item.image.childImageSharp.fluid} alt={item.title} className="studio-services-pic"/>
                        
                        <div className="studio-services-text lh15">
                            <h2 className="studio-services-title ffpd color-gold mb30">{item.title}</h2>
                            <p className="mb40 fw300 lh15">{item.text}
                                </p>
                            <button className="studio-btn-secondary ffpd ">
                            <a href={"tel:"+props.phone}>
                              <MdPhone className="studio-footer-icon"></MdPhone> Varaa aika</a>
                              </button>
                        </div>
                    </div>
                </div>
                )  
              })
          }
          
           <div className="studio-services-points">
               {
                   services.map((item, i)=>{
                  let classes = "studio-services-point"+(active === i ? " studio-services-point--active": "");

                        return <button key={i} className={classes} onClick={()=>scrollServicesTo(i)}>
                          <div className="studio-services-point-in"></div>
                        </button>
               
                   })
               }
          </div> 
      </div>
  </div>
</section>

);

function scrollServicesTo(i){
    setActive(i);
}

function scrollRight(){
  if(services.length - 1 === active){
      setActive(0);
  } else {
      setActive(active + 1);  
  }
}
function scrollRightTimer(){
  // console.log(servicesDiv.current.getBoundingClientRect());
  if (pause) return;
  if (isNotInViewport(servicesDiv.current.getBoundingClientRect())) return;
  scrollRight();
}
}


export default Services
