import React, { useState, forwardRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MdKeyboardBackspace,
MdPhone } 
from "react-icons/md"
import BackgroundImage from "gatsby-background-image"
import {isNotInViewport} from "../utils/utils"


const Hero = forwardRef((props, ref) => {
    const data = useStaticQuery(graphql`
    {
      file(sourceInstanceName: {eq: "gallery"}, name: {eq: "hero"}) {
        childMdx {
          frontmatter {
            hero {
              title
              text
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                  
                }
              }
            }
            phone
          }
        }
      }
    }
  `)
  const slides = data.file.childMdx.frontmatter.hero;
//   console.log(slides);

const[active, setActive] = useState(0);
const[pause, setPause] = useState(false);
useEffect(()=>{
  // console.log("hero timer start");
  const timer = setInterval(scrollRightTimer, 4000);
  return ()=>{
    // console.log("hero timer stop");
    clearInterval(timer)
  }
})
  return (
    <section ref={ref} className="studio-hero mb100">
    <div className="studio-container-1200">
        <div onMouseEnter={()=>{setPause(true)}} onMouseLeave={()=>{setPause(false)}} role="presentation" className="studio-hero-in">
            <button className="studio-arrow-btn studio-arrow-btn--left" onClick={scrollLeft}>
                <div className="studio-arrow bg-gold-o01">
                  <MdKeyboardBackspace className="studio-arrow__icon"></MdKeyboardBackspace>
                </div>
            </button>
            <button className="studio-arrow-btn studio-arrow-btn--right" onClick={scrollRight}>
                <div className="studio-arrow bg-gold-o01">
                <MdKeyboardBackspace className="studio-arrow__icon"></MdKeyboardBackspace>
                </div>
            </button>
            <div className="studio-hero-slides">
                {slides.map((slide, i)=>{
                  // console.log(slide.image.childImageSharp.fluid.src);
                    let classes = "studio-hero-slide"+(active === i ? " studio-hero-slide--active": "");
                    return  (<div  key={slide.image.id} className={classes}>
                    <div className="studio-hero-columns">
                        <div className="studio-hero-column-1">
                            <h2 className="studio-hero-title-2 anim-fadeInUp ffpd fz60 mb30">
                                {slide.title}
                            </h2>
                            <div className="studio-hero-slide-text anim-fadeInUp">
                                <h3 className="studio-hero-subtitle ffr lh15 mb40 fw300">
                                   {slide.text}
                                </h3>
                                <button className="studio-btn-secondary ffpd">
                                  <a href={"tel:"+props.phone}>
                                  <MdPhone className="studio-footer-icon"></MdPhone> <span className="text"> Varaa aika</span>
                                  </a>
                                  </button>
                            </div>
                        </div>

                        <div className="studio-hero-column-2">
                            <div className="studio-hero-pic1">
                              <BackgroundImage fluid={slide.image.childImageSharp.fluid} className="studio-hero-pic1__img anim-fadeInLeft"></BackgroundImage>
                              {/* <div className="studio-hero-pic1__img anim-fadeInLeft"
                                    style={{backgroundImage: "url("+slide.image.childImageSharp.fluid.src+")"}}>
                              </div> */}
                            </div>

                        </div>
                    </div>
                </div>)
                })}
                
            </div>
        </div>
    </div>
</section>
)
function scrollRight(){
    if(slides.length - 1 === active){
        setActive(0);
    } else {
        setActive(active + 1);  
    }
}
function scrollLeft(){
    if (active === 0){
        setActive(slides.length-1);
    } else setActive (active - 1);
}
function scrollRightTimer(){
  if(pause) return;
  if (isNotInViewport(ref.current.getBoundingClientRect())) return;

  scrollRight();
}
  });
  


export default Hero
