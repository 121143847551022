import React, { useState, useEffect, createRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { Link } from "gatsby"

// import Layout from "../components/layout"
import SEO from "../components/seo"

import Footer from "../components/footer"
import Testimonials from "../components/testimonials"
import Prices from "../components/prices"
import Portfolio from "../components/portfolio"
import Services from "../components/services"
import Hero from "../components/hero"
import Mainmenu from "../components/mainmenu"

const IndexPage = (props) =>  {
  const data = useStaticQuery(graphql`
  {
    file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
        childMdx {
          frontmatter {
            sitename
            address
            address2
            phone
            hours
            instagram
            facebook
            twitter
            googlemaplink
            googlemaphtml
          }
        }
      }
  }
`);
  const settings = data.file.childMdx.frontmatter;
  // console.log(settings);
  const [menuVisible, setMenuVisible] = useState(false);
  const heroDiv = createRef();

  useEffect(()=>{
    window.addEventListener("scroll", checkMenuVisible);
    return ()=>{
      window.removeEventListener("scroll", checkMenuVisible);
    }
  })
    // console.log();
    return (
        <div className="studio-page ffr">
          <SEO keywords={[`parturi`, `kampaamo`, `Tampere`, `Hervanta`,`Alessi`, `leikkaus`,`värjäys`, `permanentti`]}
          />
      <Mainmenu settings={settings} visible={menuVisible}></Mainmenu>
      <Hero ref={heroDiv} phone={settings.phone}></Hero>
      <Services phone={settings.phone}></Services>
      <Prices></Prices>
      <Testimonials></Testimonials>
      <Portfolio instagram={settings.instagram}></Portfolio>
      <Footer settings={settings}></Footer>
</div>
    )
  function checkMenuVisible() {
    // console.log(heroDiv.current);
    if(!heroDiv.current) return;

    if (heroDiv.current.getBoundingClientRect().bottom < 0){
      // console.log("visible")
      setMenuVisible(true);
    }
    else{
      // console.log("invisible")
        setMenuVisible(false);
    }
  }
}

export default IndexPage
