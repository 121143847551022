import React, { useState, useEffect, createRef } from "react"
import { MdKeyboardBackspace } from "react-icons/md"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import testimonialsWm from "../../content/assets/testimonials-wm.svg"
import {isNotInViewport} from "../utils/utils"

const Testimonials = props => {
    const [selected, setSelected] = useState(0);
    const data = useStaticQuery(graphql`
    {
      file(sourceInstanceName: {eq: "testimonials"}) {
        childMdx {
          frontmatter {
            sitename
            testimonials {
              name
              text
              image {
                id
                childImageSharp {
                  fixed(width: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const testimonials = data.file.childMdx.frontmatter.testimonials;
  const testimonialsDiv = createRef();
// console.log(testimonials);
const[pause, setPause] = useState(false);
useEffect(()=>{
  // console.log("testimonials timer start");
  const timer = setInterval(scrollRightTimer, 4000);
  return ()=>{
    // console.log("testimonials timer stop");
    clearInterval(timer)
  }
})
 return ( <section ref={testimonialsDiv} className="studio-testimonials mb100">
 <h1 className="studio-big-tittle">
   <img src={testimonialsWm} alt="suositukset"></img>
 </h1>
 <div className="studio-container-950">
     <div className="studio-testimonials-maintitle mb60 tac">
         <h3 className="studio-testimonials-subtitle ffpd tac color-gold">Mitä Asikkaamme Sanovat</h3>
         <h1 className="studio-title studio-testimonials-title ffpd tac fz60">SUOSITUKSET</h1>
     </div>
 <div className="studio-testimonials-in">
                <div onMouseEnter={()=>{setPause(true)}} onMouseLeave={()=>{setPause(false)}} role="presentation" className="studio-testimonials-main">
                    <div className="studio-arrow-btn studio-arrow-test--left">
                        <button onClick = {scrollTestimonialsLeft} className="studio-arrow bg-gold-o01">
                        
                        <MdKeyboardBackspace className="studio-arrow__icon"></MdKeyboardBackspace>
                        </button>
                    </div>
                    {
                        testimonials.map((testimonial, index) => {
                            let classes = "studio-testimonials-slide anim-fadeIn" + (index === selected ? " studio-testimonials-slide--active":"");
                        return <div key={testimonial.image.id} className={classes}>
                            {/* <div className="studio-testimonials-icon" style={{backgroundImage: "url("+testimonial.image.childImageSharp.fluid.src+")"}}></div> */}
                            <BackgroundImage className="studio-testimonials-icon" fixed={testimonial.image.childImageSharp.fixed}/>
                            <p className="studio-testimonials-text ffpd fz24">
                                {testimonial.text}
                            </p>
        `                   <p className="studio-testimonials-name ffpd fz24 fwb tac color-gold">{testimonial.name}</p>
                        </div>
                        })
                    }
                    <div className="studio-arrow-btn  studio-arrow-btn--right studio-arrow-test--right">
                        <button onClick = {scrollTestimonialsRight} className="studio-arrow bg-gold-o01">
                        <MdKeyboardBackspace className="studio-arrow__icon"></MdKeyboardBackspace>
                        </button>
                    </div>

                </div>
            </div>
  </div>
    </section>
)

function scrollTestimonialsRight(){
    //  console.log('scrollTestimonialsRight');
    if(testimonials.length - 1 === selected){
        setSelected(0);
    } else {
        setSelected(selected + 1);
    }
   
}

function scrollTestimonialsLeft(){
    if(selected === 0){
        setSelected(testimonials.length - 1);
    } else {
        setSelected(selected - 1);
    }
}
function scrollRightTimer(){
  if(pause) return;
  if (isNotInViewport(testimonialsDiv.current.getBoundingClientRect())) return;
  scrollTestimonialsRight();
}

}


export default Testimonials
