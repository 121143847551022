import { useStaticQuery, graphql } from "gatsby"
import React  from "react"
import {chunkifyArray} from "../utils/utils"
const Prices = props => {
    const data = useStaticQuery(graphql`
    {
      file(sourceInstanceName: {eq: "settings"}, name: {eq: "prices"}) {
        childMdx {
          frontmatter {
            prices {
              title
              subtitle
              price
            }
          }
        }
      }
    }
  `);
  const prices = data.file.childMdx.frontmatter.prices;
  const priceCols = chunkifyArray(prices, 2, true);
    // console.log(priceCols);
 return (
    <section className="studio-price mb100">
    <div className="studio-container-1200">
        <div className="studio-price-in studio-in">
            <div className="studio-price-maintitle mb60 tac">
                <h3 className="studio-price-subtitle ffpd tac color-gold">On Paras Teille</h3>
                <h1 className="studio-title studio-price-title ffpd tac fz60">HINNAT</h1>
            </div>
            <div className="studio-price-columns">
                
                    {
                        priceCols.map(function (col, i){
                            return <div key = {i} className="studio-price-column">
                                    {
                                        col.map((item, i)=>{
                                            return (<div key = {i} className="studio-price-row-item mb20">
                                                <div className="studio-price-row">
                                                    <span className="studio-price-name fz18">{item.title}</span>
                                                    <span className="studio-price-dot"></span>
                                                    <span className="studio-price-item">{item.price}</span>
                                                </div>
                                                <div className="studio-price-row--small fz12 fw300">{item.subtitle}</div>
                                            </div> )
                                        })
                                    }
                            </div>
                        })
                    }

            </div>
        </div>
    </div>
</section>
)
}



export default Prices
