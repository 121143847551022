import React, { useState } from "react"
import Image from "gatsby-image"
import { chunkifyArray } from "../utils/utils"
import { MdClose } from "react-icons/md"

const Gallery = props => {
  const [zoomedId, setZoomedId] = useState("");
  const columns = chunkifyArray(props.pictures, props.columnsNum, true);
  // console.log(props);

  return (
    <div>
      <div className="studio-portfolio-zoom" style={{display: zoomedId ? "block":"none"}} role="presentation" onClick={()=>setZoomedId("")} >
            { zoomedId && getImageById(zoomedId) }
          {/* <div className="studio-portfolio-overlay">
          </div>
          <div className="studio-portfolio-zoom-pic">
             
          </div> */}
          <div className="studio-portfolio-zoom-close"><MdClose></MdClose></div>
      </div>
      <div className="studio-portfolio-columns mb60">
            {columns.map((col, i)=>{
              return (
                <div key={i} className="studio-portfolio-column">
                    {col.map((picture)=>{
                  // console.log(picture);
                      
                      return (
                        <div key={picture.image.id} onClick={()=>setZoomedId(picture.image.id)} role="presentation" className="studio-portfolio-img">
                          <Image fluid={picture.image.childImageSharp.fluid}/>
                          {/* <img src={picture.image.childImageSharp.fluid.src}></img> */}
                        </div>
                      )
                    })}
                </div>
              )
            })}
      </div>
    </div>
)

// function zoomImage(id){
//   console.log(id);
//   setZoomedId(id);
// }

function getImageById(id){
  let selected = props.pictures.filter((pic)=> pic.image.id === id);
  // console.log(selected, );
  return selected.length ? <Image fluid={selected[0].image.childImageSharp.fluid} alt="" className="studio-portfolio-zoom-img" imgStyle={{objectFit: "contain"}}/> : null;
  // return selected.length ? <img src={selected[0].image.childImageSharp.fluid.src} alt="" className="studio-portfolio-zoom-img"/> : null;
}

}



export default Gallery
